import React from 'react';
import type { UserInteractionsDisplayProps, UserRoleDisplayProps } from 'components/AltHeader/types';
import { withConditionalRender } from 'hocs/withConditionalRender';
import AccountAvatar from 'components/AccountAvatar/AccountAvatar';
import { UserInteractionsDisplay, UserRoleDisplay } from 'components/AltHeader/components';
import type { CanvasHeaderProps } from '../CanvasHeader';
import { getAddressDisplay } from './getAddressDisplay';

const AccountAvatarWithConditionalRender = withConditionalRender()(AccountAvatar);
const UserRoleDisplayWithConditionalRender = withConditionalRender()(UserRoleDisplay);
const UserInteractionsDisplayWithConditionalRender = withConditionalRender()<UserInteractionsDisplayProps>(UserInteractionsDisplay);

export interface PrepareCanvasHeaderElementsParams {
  roleName?: UserRoleDisplayProps['roleName'];
  billTo: CanvasHeaderProps['billTo'];
  shipTo: CanvasHeaderProps['shipTo'];
  interactions: CanvasHeaderProps['interactions'];
  isSmScreen?: boolean;
  isMdScreen?: boolean;
}

export const prepareCanvasHeaderElements = (params: PrepareCanvasHeaderElementsParams) => {
  const {
    roleName,
    billTo,
    shipTo,
    interactions,
    isMdScreen = false,
    isSmScreen = false,
  } = params;

  const addressDisplay = getAddressDisplay({ isMdScreen, isSmScreen });

  const billToAccountName = billTo?.accountNumber ? billTo.name : '';
  const billToAccountNumber = billTo?.accountNumber ?? billTo?.name;

  const shipToAccountName = shipTo?.accountNumber ? shipTo.name : '';
  const shipToAccountNumber = shipTo?.accountNumber ?? shipTo?.name;

  const shouldRenderUserRole = roleName !== undefined;
  const shouldRenderBillToAccountAvatar = !!(billTo && (billToAccountName || billToAccountNumber));
  const shouldRenderShipToAccountAvatar = !!(shipTo && (shipToAccountName || shipToAccountNumber));
  const shouldRenderInteractions = !!interactions && (!Array.isArray(interactions) || interactions.length > 0);

  return [
    <UserRoleDisplayWithConditionalRender roleName={roleName ?? ''} shouldRender={shouldRenderUserRole} />,
    <AccountAvatarWithConditionalRender
      accountType="BT"
      addressDisplay={addressDisplay}
      name={billToAccountName ?? ''}
      address={{
        address1: billTo?.addressLine1,
        address2: billTo?.addressLine2,
      }}
      accountNumbers={{
        billTo: `${billToAccountNumber}`,
      }}
      shouldRender={shouldRenderBillToAccountAvatar}
    />,
    <AccountAvatarWithConditionalRender
      accountType="ST"
      addressDisplay={addressDisplay}
      name={shipToAccountName ?? ''}
      address={{
        address1: shipTo?.addressLine1,
        address2: shipTo?.addressLine2,
      }}
      accountNumbers={{
        shipTo: `${shipToAccountNumber}`,
      }}
      shouldRender={shouldRenderShipToAccountAvatar}
    />,
    <UserInteractionsDisplayWithConditionalRender shouldRender={shouldRenderInteractions}>
      {interactions}
    </UserInteractionsDisplayWithConditionalRender>,
  ].filter((element) => element.props.shouldRender === undefined || element.props.shouldRender === true);
};
